import $ from 'jquery';

$(($) => {

	//HEADER POPUP (menu and search)

	// Close popup when clicking outside elsewhere on the page
	$(document).on('click', function () {
		$('.headerPopup').removeClass('-isOpen');
	});

	$('.headerPopup').on('click', function(e){
		e.stopPropagation();
	});

	//popup
	$('.js-toggle-headerPopup').on('click', function() {
		var $popup = $('.headerPopup').first();
		if ($popup.hasClass('-isOpen')) {
			$popup.removeClass('-isOpen');
		}
		else {
			$popup.addClass('-isOpen');
		}
	});

	if ($('.js-SubNav').length > 0) {
		const observer = new IntersectionObserver( 
			([e]) => e.target.toggleAttribute('stuck', e.intersectionRatio < 1),
			{threshold: [1]}
		);
		observer.observe(document.querySelector('.js-SubNav'));
	}


	

	//TODO: Do we still need this?

	function externalToggler() {
		$('.JS-externalToggle').toggleClass('-is-active');
		$('.externalLinks').toggleClass('-is-active');
	}

	$('.JS-externalToggle').on('click keyup', function (event) {
		if (event.keyCode === 13) {
			externalToggler();
		} else if (event.handleObj.type === 'click') {
			externalToggler();
		}
	});
});