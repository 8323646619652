import AOS from 'aos';
import $ from 'jquery';

$(() => {
	//https://github.com/michalsnik/aos/tree/v2
	//initiation on elements with data-aos="fade-up"

	AOS.init({
		delay: 100,
		duration: 500,
		ease: 'ease-in-out',
	});

	// https://github.com/michalsnik/aos/issues/182
	window.addEventListener('load', AOS.refresh);
});
