// https://www.npmjs.com/package/slick-carousel
import $ from 'jquery';

$(($) => {
	if ($('.js-GenericSlideshow > div').length > 1) {
		$('.js-GenericSlideshow').slick({
			arrows: true,
			dots: true,
			fade: true,
			autoplay: false,
			nextArrow:
				'<button class="slick-next slick-arrow carousel__slickArrow" aria-label="Next" type="button"><span class="icon">></span></div>',
			prevArrow:
				'<button class="slick-prev slick-arrow carousel__slickArrow" aria-label="Prev" type="button"><span class="icon"><</span></div>',
			responsive: [
				{
					breakpoint: 768,
					settings: {
						arrows: false,
					},
				},
			],
		});
	}

	$('.js-GallerySlideshow-small').slick({
		arrows: true,
		dots: true,

		autoplay: false,
		slidesToShow: 5,
		nextArrow:
			'<button class="slick-next slick-arrow carousel__slickArrow" aria-label="Next" type="button"><span class="icon">></span></div>',
		prevArrow:
			'<button class="slick-prev slick-arrow carousel__slickArrow" aria-label="Prev" type="button"><span class="icon"><</span></div>',

		responsive: [
			{
				breakpoint: 1440,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					slidesToShow: 1,
				},
			},
		],
	});

	$('.js-GallerySlideshow-large').slick({
		arrows: true,
		dots: true,
		centerMode: true,
		centerPadding: '120px',
		autoplay: false,
		slidesToShow: 3,
		nextArrow:
			'<button class="slick-next slick-arrow carousel__slickArrow" aria-label="Next" type="button"><span class="icon">></span></div>',
		prevArrow:
			'<button class="slick-prev slick-arrow carousel__slickArrow" aria-label="Prev" type="button"><span class="icon"><</span></div>',

		responsive: [
			{
				breakpoint: 1752,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 768,
				settings: {
					centerPadding: '80px',
					arrows: false,
					slidesToShow: 1,
				},
			},
			{
				breakpoint: 500,
				settings: {
					centerPadding: '40px',
					arrows: false,
					slidesToShow: 1,
				},
			},
		],
	});

	function addOrRemoveNonSliderClasses() {
		$('.js-GallerySlideshow-small').each(function () {
			if (
				(window.matchMedia('(min-width: 1440px)').matches &&
					$(this).find('.gallery__item').length < 6) ||
				(window.matchMedia('(max-width: 1439px)').matches &&
					$(this).find('.gallery__item').length < 4) ||
				(window.matchMedia('(max-width: 767px)').matches &&
					$(this).find('.gallery__item').length < 2)
			) {
				$(this).addClass('-non-slider');
			} else {
				$(this).removeClass('-non-slider');
			}
		});

		$('.js-GallerySlideshow-large').each(function () {
			if (
				(window.matchMedia('(min-width: 1752px)').matches &&
					$(this).find('.gallery__item').length < 4) ||
				(window.matchMedia('(max-width: 1751px)').matches &&
					$(this).find('.gallery__item').length < 3) ||
				(window.matchMedia('(max-width: 767px)').matches &&
					$(this).find('.gallery__item').length < 2)
			) {
				$(this).addClass('-non-slider');
			} else {
				$(this).removeClass('-non-slider');
			}
		});
	}

	$(window).on('resize', function (e) {
		addOrRemoveNonSliderClasses();
	});

	addOrRemoveNonSliderClasses();

	$('.js-recentNewsSlider').slick({
		arrows: true,
		autoplay: false,
		slidesToShow: 5,
		nextArrow:
			'<button class="slick-next slick-arrow carousel__slickArrow" aria-label="Next" type="button"><span class="icon">></span></div>',
		prevArrow:
			'<button class="slick-prev slick-arrow carousel__slickArrow" aria-label="Prev" type="button"><span class="icon"><</span></div>',

		responsive: [
			{
				breakpoint: 1752,
				settings: {
					slidesToShow: 4,
				},
			},
			{
				breakpoint: 1220,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '6vw',
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 450,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '6vw',
					slidesToShow: 1,
				},
			},
		],
	});
});
