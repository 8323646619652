import $ from 'jquery';

$(() => {
	// TODO: Either use jquery or don't
	const forms = document.querySelectorAll('.form');
	forms.forEach((form) => {
		const textInputs = form.querySelectorAll(
			'input[type="text"],' +
				'input[type="email"],' +
				'textarea,' +
				'input[type="password"],' +
				'input[type="tel"],' +
				'input[type="date"],' +
				'input[type="time"],' +
				'input[type="url"],' +
				'input[type="checkbox"],' +
				'input[type="number"]',
		);
		textInputs.forEach((input) => {
			checkTextInputValue(input);
			attachTextInputEventHandler(input);
		});
		const fileInputs = form.querySelectorAll('input[type="file"]');
		fileInputs.forEach((input) => {
			attachFileSelectedHandler(input);
		});
	});

	$('input[type="tel"]').on('change input', function (e) {
		const val = $(this).val() || '';

		const safe = val.replace(/[^0-9 ()+#]/gu, '');

		if (val !== safe) {
			$(this).val(safe).trigger(e.type);
			e.preventDefault();
			return false;
		}
	});
});

function attachTextInputEventHandler(input) {
	input.addEventListener('focus', (e) => {
		const formField = e.target.parentElement;
		formField.classList.add('focus');
	});
	input.addEventListener('blur', (e) => {
		const formField = e.target.parentElement;
		formField.classList.remove('focus');
		checkTextInputValue(input);
	});
	input.addEventListener('change', () => {
		checkTextInputValue(input);
	});
}

function checkTextInputValue(input) {
	if (input.value) {
		input.parentElement.classList.add('has-value');
	} else {
		input.parentElement.classList.remove('has-value');
	}
}

function attachFileSelectedHandler(input) {
	const label = input.parentElement.querySelector('label');

	input.addEventListener('change', (e) => {
		let selectedFileName = '';
		const fileInput = e.target;
		if (fileInput.files && fileInput.files.length > 1) {
			// multiple files selected, use multiple-caption data tag if given
			selectedFileName = (
				fileInput.getAttribute('data-multiple-caption') || ''
			).replace('{count}', fileInput.files.length);
		} else {
			// single file, get the filename only from the full path
			selectedFileName = fileInput.value
				.split('\\')
				.pop()
				.split('/')
				.pop();
		}

		const selectedFile = label.parentElement.querySelector(
			'.selected-file',
		);
		selectedFile.innerHTML = '';
		const selectedFileNameNode = document.createElement('span');
		selectedFileNameNode.classList.add('selected-file__name');
		selectedFileNameNode.innerHTML = selectedFileName;
		selectedFile.appendChild(selectedFileNameNode);

		const removeButton = document.createElement('span');
		removeButton.classList.add('selected-file__button');
		removeButton.type = 'button';
		removeButton.classList.add('selected-file__button');
		removeButton.innerHTML =
			'<i class="fal fa-times selected-file__icon"></i>';
		removeButton.onclick = function () {
			input.value = '';
			selectedFile.innerHTML = '';
			selectedFileName = null;
			updateFileLabel();
		};

		selectedFile.appendChild(removeButton);

		updateFileLabel();
		function updateFileLabel() {
			if (selectedFileName) {
				label.parentElement.classList.add('has-value');
			} else {
				label.parentElement.classList.remove('has-value');
			}
		}
	});
}

/**
 * Clearable text inputs
 */
$('.clearable').each(function () {
	const $input = $(this).find('[type=text], [type=search]');
	const $clear = $(this).find('.clearable__clear');

	$input.on('input', function () {
		if (this.value) {
			$clear.addClass('show');
		} else {
			$clear.removeClass('show');
		}
	});

	$clear.on('touchstart click', function (e) {
		e.preventDefault();
		$input.val('').trigger('input');
	});
});
