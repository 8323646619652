import $ from 'jquery';

$(($) => {
	$('#ContactForm').validate({
		rules: {
			fromName: 'required',
			fromEmail: {
				required: true,
				email: true,
			},
			'message[body]': 'required',
		},
		messages: {
			fromName: 'Please enter your name',
			fromEmail: {
				required: 'Please enter your email address',
				email: 'Please enter a valid email address',
			},
			'message[body]': 'Please enter your message',
		},
		submitHandler: (form) => {
			form.submit();
		},
	});
});
