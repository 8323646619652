import 'jquery-validation';
// Plugins
// import 'mmenu-js';
// import 'imagesloaded';
import 'lazysizes';
import 'lazysizes/plugins/bgset/ls.bgset';
// Modules
import './modules/animations'; //animate on scroll
import './modules/css';
import './modules/cssHelper';
import './modules/faqs';
import './modules/filters';
import './modules/form';
import './modules/form-validation';
import './modules/image-gallery';
import './modules/mobile-menu';
import './modules/pageHeader';
import './modules/slick-carousel';
import './modules/svgIcon-sprite';
