import $ from 'jquery';
$(function ($) {
	const urlParams = new URLSearchParams(window.location.search);
	const selectedMajorFilter = urlParams.get('majorFilter');
	const selectedMinorFilter = urlParams.get('minorFilter');

	$('#majorFilter > option').each(function () {
		if (this.value == selectedMajorFilter) {
			this.selected = 'selected';
		}
	});
	$('#minorFilter > option').each(function () {
		if (this.value == selectedMinorFilter) {
			this.selected = 'selected';
		}
	});
});
